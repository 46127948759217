@use '../../includes' as *;

.change_station_error_panel {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: $g20;
  width: 400px;
  padding: $g8;

  @include breakpoint($sm) {
    gap: $g24;
  }

  p {
    @include body-copy-2;

    text-align: center;
  }
}

.change_station_error_panel__header {
  @include h6;
}
