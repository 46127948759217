@use '../includes' as *;

.white {
  @include btn;
  @include fill-btn($white);
}

.blue {
  @include btn;
  @include fill-btn($pbs-blue);
}

.light_blue {
  @include btn;
  @include fill-btn($light-blue);
}

.white_ghost {
  @include btn;
  @include ghost-btn($white);
}

.blue_ghost {
  @include btn;
  @include ghost-btn($pbs-blue);
}

.yellow {
  @include btn;
  @include fill-btn($yellow);
}

.red {
  @include btn;
  @include fill-btn($red);
}

// these extra classes are for
// buttons that should not have responsive
// padding, font-size, or border-width
.min {
  padding: $g8 $g20;
  border-width: 1px;
  font-size: $f12;
}

.max {
  padding-inline: $g24;
  border-width: 2px;
  font-size: $f16;
}
