@use '../includes' as *;

.highlight {
  display: grid;
  grid-template-areas:
    'image'
    'information';
  width: 100%;
  margin-block-end: var(--spacing-block);
  padding-inline: var(--spacing-inline);
  overflow: hidden;
  border-radius: $radius-large;

  @include breakpoint($sm) {
    grid-template-areas: 'information image';
    grid-template-columns: 1fr 1.6fr;
    padding: unset;
    border-radius: 0;
  }

  @include breakpoint($md) {
    grid-template-columns: 1fr 1fr;
  }
}

.highlight_block__description {
  display: flex;
  position: relative;
  grid-area: information;
  flex-direction: column;
  justify-content: center;
  gap: $g12;
  padding-block: $g16;
  padding-inline: var(--spacing-inline);
  border-radius: 0 0 10px 10px;
  background-color: $navy-blue;

  @include breakpoint($sm) {
    padding-block: $g32;
    padding-inline-end: 0;
    transition-property: box-shadow;
    transition-duration: var(--duration);
    box-shadow: 0 0 75px 75px $navy-blue;
  }
}

// Bumping specificity to override font-size
// Designers requested 11px across all breakpoints
span.highlight_badge {
  width: fit-content;

  @include over-title($yellow);
}

.highlight_first_title {
  @include h2;
}

.highlight_second_title {
  @include h3;
}

.highlight_summary {
  display: block;

  @include body-copy-2;
}

.highlight__video_playing {
  box-shadow: 0 0 10px 15px $navy-blue;
}

.highlight_logo {
  width: auto;
  max-width: 200px;
  height: auto;
  max-height: clamp(3.2rem, 1.607vw + 2.686rem, 5rem);
}

.highlight_text {
  @include body-copy-3;

  max-width: 400px;

  @include breakpoint($sm) {
    @include typography-truncate(1);
  }

  @include breakpoint($smmd) {
    @include typography-truncate(2);
  }

  @include breakpoint($md) {
    @include body-copy-1;
    @include typography-truncate(4);
  }
}

.highlight__controls {
  display: flex;
  align-items: center;
  gap: $g16;

  @include breakpoint($sm) {
    container-type: inline-size;
  }
}

div.highlight__controls_kabob {
  position: absolute;
  inset-block-start: $g8;
  inset-inline-end: var(--spacing-inline);

  button {
    @include breakpoint($max: $sm) {
      // Moving the kabob icon closer to the edge
      margin-inline-end: -16px;
      padding: unset;
      border: 0;
    }
  }

  @include breakpoint($sm) {
    position: relative;
    inset: 0;
  }
}

.highlight_poster {
  grid-area: image;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 10px 10px 0 0;

  iframe {
    height: 100%;
  }

  @include breakpoint($sm) {
    border-radius: unset;
  }
}

.highlight_poster__image {
  object-fit: cover;
}

.highlight_explore_the_show {
  &:hover,
  &:focus,
  &:active {
    svg {
      transition: fill var(--duration-half) ease-in;
      fill: var(--link-active-color);
    }
  }
}
