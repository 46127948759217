@use '../includes' as *;

.splide__list {
  &:nth-child(1) {
    padding-inline-start: 90px;
    background-color: white;
  }
}

.show_poster__link {
  display: block;
  position: relative;
  z-index: 10;
  height: 100%;
  overflow: clip;
  transition-property: scale, opacity, border-radius, outline-color;
  transition-duration: var(--duration);
  transition-timing-function: ease-in-out;
  border-radius: clamp(4px, 4%, 9px);
  outline: 3px solid transparent;
  outline-offset: -0.5px;
  background-color: $midnight;

  @media (hover: hover) {
    &:hover,
    &:focus {
      z-index: 11;
      outline-color: $white;
      scale: 1.1;
    }
  }

  img {
    width: 100%;
    height: auto;
  }

  &:has(.show_poster__fallback) {
    background-color: $blue-gray;
  }
}

.show_poster__with_image {
  background-color: transparent;
}

.show_poster__fallback {
  @include h3;

  flex-grow: 1;
  padding: 35% $g16 $g16;
  color: $fog;
  text-align: center;
}
