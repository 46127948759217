@use '../../includes' as *;

.change_station_panel {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: $g20;
  width: 400px;

  @include breakpoint($sm) {
    gap: $g24;
  }
}

.change_station_panel__header {
  @include h6;

  font-size: $f12;
}

.change_station_panel__buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: $g12;

  @include breakpoint($sm) {
    flex-direction: row;
  }

  button {
    width: fit-content;
  }
}

.change_station_panel__stations_list {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: $g16;
  max-height: 460px;
  overflow-y: scroll;

  // Custom Scrollbar styling

  // Chrome & Firefox have the standards-based scrollbar rules
  // https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Scrollbars
  @supports (scrollbar-color: initial) {
    scrollbar-color: $pebble transparent;
  }

  // Safari uses -webkit pseudeo elements
  // https://css-tricks.com/almanac/properties/s/scrollbar/
  @supports not (scrollbar-color: initial) {
    &::-webkit-scrollbar {
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      min-height: 40px;
      border: 4px solid transparent;
      border-radius: 15px;
      background-color: $pebble;
    }
  }
}
