@use '../../../components/includes' as *;

$cpb: 650px;

.search_web_thumbnail__container {
  container-type: inline-size;
  width: clamp(320px, 100%, 836px);
}

.search_web_thumbnail {
  display: grid;
  grid-template-areas:
    'thumbnail     title       button'
    'description   description .';
  grid-template-columns: 1fr 2fr 21px;
  align-items: center;
  gap: $g4 $g8;

  @container (min-width: #{$cpb}) {
    grid-template:
      'thumbnail title      '
      'thumbnail description';
    grid-template-columns: 1fr 2fr;
    grid-template-rows: 1fr 1fr;
    gap: $g8 $g16;
  }

  @container (min-width: 750px) {
    gap: $g8 $g20;
  }
}

.search_web_thumbnail__fallback_link {
  max-height: 100px;
  aspect-ratio: 16 / 9;
  background: radial-gradient(
    ellipse at center,
    #eef0f5 0,
    #e0e0e0 50%,
    #e0e0e0 50%,
    #b3b3b3 100%
  );
}

.search_web_thumbnail__link,
.search_web_thumbnail__fallback_link {
  --border-thickness: 2px;

  display: block;
  position: relative;
  grid-area: thumbnail;

  // hard coded values derived from Video Detail Thumbnail image min & max widths
  width: clamp(94.3px, 100%, 272px);
  transition-property: scale, border-radius, outline-color, opacity;
  transition-duration: var(--duration);
  outline: var(--border-thickness) solid transparent;
  background-repeat: no-repeat;
  background-size: cover;

  @include breakpoint($sm) {
    --border-thickness: 3px;
  }

  &:hover {
    border-radius: var(--border-thickness);
    outline-color: $white;
    scale: 1.03;
  }
}

.search_web_thumbnail__image {
  width: auto;
  max-height: 100px;
  margin: 0 auto;
}

.search_web_thumbnail__title {
  @include h4;

  grid-area: title;
  align-self: center;

  @container (min-width: #{$cpb}) {
    align-self: end;
  }

  .video_detail_thumbnail:has(.parent_link) & {
    align-self: start;

    @container (min-width: #{$cpb}) {
      align-self: center;
    }
  }
}

.search_web_thumbnail__description {
  @include body-copy-3($pebble);
  @include typography-truncate(2);

  grid-area: description;
  margin-block-end: $g4;

  @container (min-width: #{$cpb}) {
    align-self: start;
  }

  @container (min-width: 750px) {
    @include body-copy-1($pebble);
  }
}
