@use '../../includes' as *;

.station_passport_row {
  position: relative;
  padding: $g48 var(--spacing-inline);
  background-color: $midnight;

  @include breakpoint($sm) {
    padding-block: $g32;
  }

  @include breakpoint($lg) {
    padding-block: 5%;
  }
}

.station_passport_row_background_image {
  position: absolute;
  inset: 0;
  width: 100%;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0.3;
  }
}

.station_passport_row_overlay {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  gap: $g12;

  @include breakpoint($sm) {
    gap: $g16;
  }
}

h2.station_passport_row_title {
  @include h1;
}

.station_passport_row_text {
  @include body-copy-3;

  max-width: 375px;
  text-align: center;

  @include breakpoint($md) {
    @include body-copy-1;

    max-width: 550px;
  }
}

.member_lookup {
  @include body-copy-3;

  a {
    margin-inline-start: $g4;
    transition: opacity var(--duration-half) ease-in;
    color: $yellow;
    font-weight: $bold;

    &:hover,
    &:focus {
      opacity: 0.8;
    }
  }
}
