@use '../../includes' as *;

.my_list_show {
  position: relative;
  transition-property: scale, opacity, border-radius, outline-color;
  transition-duration: var(--duration);
  transition-timing-function: ease-in-out;
  outline: 3px solid transparent;
  outline-offset: -0.5px;

  > a:hover,
  > a:focus {
    outline-color: transparent;
    // we don't want the posters to scale - we need the whole item to scale
    scale: 1;
  }

  @media (hover: hover) {
    &:hover,
    &:focus-within {
      z-index: 22;
      border-radius: clamp(4px, 4%, 9px);
      outline-color: $white;
      scale: 1.1;
    }
  }
}

.my_list_show_kabob_menu {
  @include my-list-kabob-menu;

  @media (hover: hover) {
    opacity: 0;

    &:focus-visible,
    .my_list_show:hover &,
    .my_list_show:has(> a:focus) & {
      opacity: 1;
    }
  }
}

.my_list_show_link,
.my_list_show_remove_button {
  display: flex;
  align-items: center;
  gap: $g12;
  padding: 0;
  border: 0;
  background-color: transparent;
  color: $fog;
  cursor: pointer;

  // stylint thinks this is descneding specificty, but it's not
  // stylelint-disable-next-line
  svg {
    width: 15px;
    height: 15px;
  }

  &:hover,
  &:focus {
    color: $pebble;

    svg,
    path,
    rect {
      fill: currentcolor;
    }
  }
}
