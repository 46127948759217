@use '../../includes' as *;

.pbs_app {
  container-type: inline-size;
  background-color: $navy-blue;
}

.pbs_app_inner {
  display: grid;
  grid-template-areas:
    'title'
    'text'
    'logos'
    'illustration';
  gap: $g24;
  padding: $g28 var(--spacing-inline) $g20 var(--spacing-inline);
  background-color: $navy-blue;

  @container (min-width: #{$sm}) {
    grid-template-areas:
      '. illustration'
      'title illustration'
      'text illustration'
      'logos illustration'
      '. illustration';
    grid-template-columns: 1fr 1.3fr;
    align-items: center;
    column-gap: clamp(36px, 4vw, 64px);
    margin-inline: auto;
    padding-block: clamp(36px, 4vw, 44px);
  }
}

.pbs_app_title {
  @include h2;

  grid-area: title;
  align-self: center;
  margin-block-start: auto;
  text-wrap: pretty;
}

.pbs_app_text {
  @include body-copy-3;

  grid-area: text;
  text-wrap: pretty;

  @include breakpoint($md) {
    @include body-copy-1;
  }
}

.app_logo_list {
  display: flex;
  container-type: inline-size;
  grid-area: logos;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  gap: 4.1%;
  row-gap: 4%;
  max-width: 488px;
}

.app_logo {
  height: auto;
  margin-block-end: 4%;

  &.ios {
    width: 7.2%;
  }

  &.apple_tv {
    width: 8.4%;
  }

  &.roku {
    width: 13.3%;
  }

  &.android {
    width: 25%;
  }

  &.fire_tv {
    width: 10.4%;
  }

  &.chromecast {
    width: 26.9%;
  }

  &.android_tv {
    width: 21.9%;
  }

  &.samsung {
    width: 23.2%;
  }

  &.vizio {
    width: 15.5%;
  }
}

.devices_in_use {
  grid-area: illustration;
  width: 100%;
}
