@use '../includes' as *;

$bp: $md;

.franchise_hero {
  container-type: inline-size;
  position: relative;
  aspect-ratio: 16 / 9;
  padding-block-start: 30%;

  @include breakpoint($bp) {
    aspect-ratio: 16 / 9;
  }

  @include breakpoint($xl) {
    aspect-ratio: 20 / 9;
  }
}

.franchise_hero_background_image {
  position: absolute;
  inset: 0 0 auto;
  width: auto;
  height: 100%;

  @include breakpoint($bp) {
    position: absolute;
    inset: 0 0 0 auto;
    aspect-ratio: 16 / 9;
  }

  img {
    width: 100%;
    object-fit: cover;
    mask-image: linear-gradient(
      to bottom,
      transparent 5px,
      black 56px,
      black 41%,
      transparent 66%
    );

    @include breakpoint($xxs) {
      mask-image: linear-gradient(
        to bottom,
        transparent 5px,
        black 56px,
        black 48%,
        transparent 84%
      );
    }

    @include breakpoint($bp) {
      mask-composite: intersect;
      mask-image:
        linear-gradient(to right, transparent 20%, black 50%),
        linear-gradient(
          to bottom,
          transparent 5px,
          black 56px,
          black 80%,
          transparent 100%
        );
    }
  }
}

.franchise_hero_back_button {
  position: absolute;
  inset: $g20 $g20 auto auto;
  z-index: 1;

  @include breakpoint($xl) {
    inset: $g32 $g32 auto auto;
  }
}

.franchise_hero_overlay {
  display: flex;
  container-type: inline-size;
  position: relative;
  z-index: 2;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: $g12;
  max-width: calc(100% - var(--spacing-inline));
  margin-block: calc(var(--responsive-row-gap) / 2);
  padding-inline-start: var(--spacing-inline);

  @include breakpoint($xs) {
    max-width: calc(70% - var(--spacing-inline));
  }

  @include breakpoint($bp) {
    position: absolute;
    inset: 0 auto 0 0;
    gap: $g16;
    width: calc(46% - var(--spacing-inline));
    margin-block: 0;
  }

  @include breakpoint($xl) {
    gap: $g20;
  }
}

.franchise_hero_name {
  @include h1;

  max-width: calc(100vw - var(--spacing-inline));

  img {
    width: clamp(160px, 40vw, 360px);
    height: auto;
  }
}

.franchise_hero_description {
  @include body-copy-3($color: $pebble);

  max-width: calc(95vw - var(--spacing-inline));
  text-wrap: pretty;

  @include breakpoint($min: $md, $max: $lg) {
    max-width: 90%;
  }

  @include breakpoint($min: $md, $max: $xxl) {
    @include body-copy-2($color: $white);

    font-size: 12px;
  }

  @include breakpoint($xxl) {
    @include body-copy-1($color: $white);
  }

  .franchise_hero_description_more_button {
    @include body-copy-3($color: $pebble);

    border: 0;
    background-color: transparent;
    font-weight: $bold;
    text-transform: uppercase;
  }

  &.franchise_hero_description_expanding {
    @include breakpoint($bp) {
      display: none;
    }
  }

  &.franchise_hero_description_full {
    display: none;

    @include breakpoint($bp) {
      display: block;
    }
  }
}
