@use '../includes' as *;

.content_row_title_description {
  container-type: inline-size;
  position: relative;
}

$titlebp: 500px;

.content_row_title {
  @include h2;

  margin-block-end: $g8;
  scroll-margin-top: calc(var(--navigation-height) + var(--spacing-block));

  @include containerminwidth($titlebp) {
    display: flex;
    align-items: center;
  }

  @include breakpoint($md) {
    margin-block-end: $g12;
  }

  // if there is a description, we want tighter margins
  &:has(+ .content_row_description) {
    margin-block-end: 0;

    @include breakpoint($md) {
      margin-block-end: $g4;
    }
  }
}

.content_row_title__logo {
  width: auto;
  min-height: 15px;
  max-height: 25px;

  @include breakpoint($titlebp) {
    min-height: 18px;
    max-height: 35px;
  }
}

.content_row_title__logo__wrapper {
  @include containerminwidth($titlebp) {
    display: flex;
    align-items: center;

    &::after {
      content: ' | ';
      display: inline-flex;
      align-items: center;
      margin-inline: $g8;
      font-weight: $normal;
      translate: 0 -2px;
    }
  }
}

.content_row_description {
  @include body-copy-2($pebble);

  margin-block-end: $g8;
  margin-inline-end: var(--spacing-inline);

  @include breakpoint($md) {
    margin-block-end: $g12;
  }
}
