@use '../../../components/includes' as *;

.shows_landing_error {
  display: flex;
  flex-direction: column;
  gap: $g12;
  margin-block-end: var(--spacing-block);

  @include breakpoint($sm) {
    gap: $g20;
  }
}

.shows_landing_error_header {
  @include h4;

  align-self: center;
  width: max-content;

  @include breakpoint($max: $md) {
    font-size: $f14;
  }
}

.shows_landing_error_small {
  @include body-copy-3;

  align-self: center;
  width: 100%;
  max-width: 360px;
  text-align: center;

  a {
    font-weight: $bold;
  }

  @include breakpoint($md) {
    @include body-copy-2;

    max-width: 420px;
  }
}
