@use '../../includes/' as *;

.activate_error {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: calc(var(--spacing-block) * 0.5);
  width: calc(100vw - #{$g48});
  max-width: 400px;
  text-align: center;

  h2 {
    @include h1;
  }
}
