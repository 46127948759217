@use '../includes' as *;

.skip_link {
  display: block;
  position: absolute;
  inset-inline-start: -1000px;
  z-index: 2000;
  width: 1px;
  max-height: 1px;
  overflow: hidden;
  background: var(--lightest-darkest-background-color);
  color: var(--link-color);
  font-size: $f18;
  text-align: center;

  &:focus {
    position: fixed;
    inset: 0 0 auto;
    width: 100%;
    max-height: none;
    padding: $g16;
  }
}
