@use '../../../../components/includes' as *;

.mobile_controls {
  display: flex;
  position: sticky;
  inset-block-start: 58px;
  inset-inline-start: 0;
  z-index: 11;
  justify-content: space-between;
  margin-inline: -#{$g48};
  padding-block: $g16 $g8;
  padding-inline: $g48;
  background-color: var(--content-well-background-color);

  @include breakpoint($smmd) {
    display: none;
  }
}

.mobile_filters_button {
  @include btn;
  @include ghost-btn($white);
}

.mobile_sort_by_select {
  @include custom-dropdown($padding-inline-end: $g40);
}

.linear_shadow {
  position: sticky;
  inset-block-start: 113px;
  z-index: 11;
  height: 30px;
  margin-inline: -#{$g48};
  background: linear-gradient(
    to bottom,
    rgba($midnight, 100%) 0%,
    rgba($midnight, 88%) 20%,
    rgba($midnight, 36%) 67%,
    rgba($midnight, 0%) 100%
  );

  @include breakpoint($smmd) {
    display: none;
  }
}

body:has(.mobile_filters) {
  overflow: hidden;
}

.mobile_filters {
  position: fixed;
  // This magic number was found based on the top position of the filters,
  // where the sticky started, and the nav based on it scrolling away.
  // This magic number will be fixed when the mobile nav is in place
  inset-block-start: 59px;
  inset-inline-start: 0;
  z-index: 20;
  width: 100%;
  height: 100%;
  padding: 20px;
  padding-block-end: 60px;
  overflow-y: scroll;
  background: rgba($midnight, 80%);
  backdrop-filter: blur(10px);
}

.mobile_filters_title {
  display: flex;
  justify-content: space-between;

  h2 {
    @include h2;
  }

  svg {
    width: 20px;
    height: 20px;
    margin-inline-end: $g20;
  }
}

.fieldset {
  display: block;
  margin-block-start: $g40;
  margin-inline-start: 0;
  padding-inline-start: 0;
  border: none;

  &:last-of-type {
    margin-block-end: 20px;
  }
}

.legend {
  display: block;
  color: $yellow;
  font-size: $f14;
  font-weight: $bold;
  text-transform: uppercase;

  @include breakpoint($smmd) {
    font-size: $f16;
  }
}

.label {
  display: block;
  color: var(--header-color);
  font-size: $f14;
  font-weight: $bold;
}

.radio {
  display: flex;
  align-items: center;
  margin-block-start: $g20;
  padding: 0;
  border: 0;

  // custom radio button styles from _mixins.scss
  @include custom-radio;
}

.radio_label {
  margin-block-end: 0;
  padding-block-end: 0;
  color: $fog;
  font-size: $f16;
  font-weight: $normal;
}

.filters_close_icon {
  pointer-events: auto;

  &:hover {
    cursor: pointer;
  }
}
