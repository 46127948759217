@use '../../../../../components/includes' as *;

.collection_tab {
  @include content-layout-with-ad;
}

.collection_list {
  @include video-detail-thumbnail-list;
}

.collection_list_title {
  @include h4;

  margin-block-end: $g16;
}
