@use '../../../../components/includes' as *;

.form {
  display: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 18px $g16 6px;

  @include breakpoint($smmd) {
    display: block;
    min-width: 300px;
    max-height: 575px;
  }

  fieldset {
    &:first-of-type {
      margin-block-end: $g44;
    }
  }
}

.fieldset {
  display: none;
  margin-inline-start: 0;
  padding-inline-start: 0;
  border: none;

  @include breakpoint($smmd) {
    display: block;
  }
}

.legend {
  @include h6;

  display: block;
  color: $yellow;
  text-transform: uppercase;
}

.label {
  display: block;
  color: var(--header-color);
  font-size: $f14;
  font-weight: $bold;
}

.radio {
  display: flex;
  align-items: center;
  margin-block-start: $g12;
  padding: 0;
  border: 0;

  @include custom-radio;

  &:hover {
    outline: none;
  }
}

.radio_label {
  @include body-copy-2;

  margin-block-end: 0;
  padding-block-end: 0;
  color: $fog;

  &:hover {
    color: $pebble;
  }
}

.sort_by {
  display: none;
  align-items: center;
  margin: 0;
  border: none;

  @include breakpoint($smmd) {
    display: flex;
    position: absolute;
    inset-block-start: 0;
    inset-inline-end: 0;
  }

  legend {
    display: contents;
    color: $yellow;

    @include h6;

    font-weight: bold;
  }

  label {
    @include body-copy-1;

    padding: $g8 $g16;
    color: $fog;

    &:not(:hover) {
      border-color: transparent;
    }

    &:hover {
      border-color: transparent;
      color: $pebble;
      cursor: pointer;
    }
  }

  // visually hide the sort radio buttons;
  // navigate them using styled labels
  input {
    position: absolute;
    margin: 0;
    opacity: 0;
  }

  input:focus-visible + label {
    outline: $focusstatelight;
  }

  span {
    &:first-of-type {
      margin-inline-start: $g16;
    }
  }

  input:checked ~ label {
    @include h5;

    border-radius: $g8;
    // These values are used over border to prevent the labels adjusting position
    // when checked. They don't work if the linter consolidates them into a
    // single rule.
    /* stylelint-disable */
    outline: var(--color);
    outline-style: solid;
    outline-width: 2px;
    /* stylelint-enable */
    color: white;
    font-weight: bold;
  }
}

.scroll_to_top_button {
  @include btn;
  @include ghost-btn($white);

  & {
    display: none;
    position: fixed;
    inset-block-end: 100px;
    z-index: 0;
    transition-property: opacity;
    transition-duration: var(--duration-half);
    transition-timing-function: ease-in;
  }
}

.hidden {
  display: none;

  @include breakpoint($smmd) {
    display: block;
    opacity: 0;
  }
}
