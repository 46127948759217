@use '../includes' as *;

.social_links {
  display: flex;
  gap: $g8;

  li {
    margin-block-end: 0;
  }
}

.social_link {
  @include circle-icon-button;

  --color: #{$white};
  --hover-color: #{$white};

  border-color: var(--color);

  img,
  svg {
    // Known issue in Safari that svgs need
    // to set a hacky "width: 100%" to work
    // https://stackoverflow.com/a/37594943
    width: 100%;
    max-width: 60%;
    max-height: 60%;
  }

  svg,
  path {
    stroke: var(--color);
    fill: var(--color);
  }

  @include breakpoint($xl) {
    width: 35px;
    height: 35px;
  }
}

.scale_on_hover {
  transition-property: transform;

  &:hover,
  &:focus {
    transform: scale(1.1);
  }
}

.change_color_on_hover {
  transition-property: border-color;

  svg,
  path {
    transition-property: fill, stroke;
    transition-duration: var(--duration-half);
  }

  &:hover,
  &:focus {
    --color: var(--hover-color);
  }
}

.white {
  --color: #{$white};
  --hover-color: #{$white};
}

.pebble {
  --color: #{$pebble};
  --hover-color: #{$fog};
}
