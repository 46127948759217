@use '../includes' as *;

$bp: $sm;

.show_poster_video_row {
  margin-block-end: var(--spacing-block);
  padding-inline: var(--spacing-inline);
}

.show_poster_plus_carousel {
  gap: var(--show-poster-video-row-gap);
  padding-inline-start: calc(var(--spacing-inline) / 2);

  @include breakpoint($bp) {
    display: grid;
    grid-template-columns: clamp(110px, 14vw, 206px) 3fr;
    padding: unset;
  }

  div[class*='splide__track'] {
    // all of this funky offsetting by $g8 is so that
    // we can hide the flow of the carousel as designed
    // but still have space for the video thumbnails
    // to grow slightly on hover
    width: calc(100% + #{$g8});
    padding-block-start: $g8;
    // prevents cropping of the first video thumbnail on hover
    overflow: visible;
    translate: -#{$g8} -#{$g8};

    @include breakpoint($bp) {
      width: 100%;
      translate: 0 -#{$g8};
    }
  }

  // we need to further override the video thumbnail carousel
  // arrow spacing for the show poster video row
  button[class*='splide__arrow'] {
    inset-block-start: 24.5cqw;
    margin-block-start: -30px;

    @include breakpoint($xxxs) {
      inset-block-start: 24.5cqw;
    }

    @include breakpoint($xxs) {
      inset-block-start: 22.5cqw;
    }

    @include breakpoint($xs) {
      inset-block-start: 20cqw;
    }

    @include breakpoint($sm) {
      inset-block-start: 13.5cqw;
    }

    @include breakpoint($md) {
      inset-block-start: 12.5cqw;
    }

    @include breakpoint($lg) {
      inset-block-start: 11.5cqw;
    }

    @include breakpoint($xl) {
      inset-block-start: 9.5cqw;
    }
  }

  button[class*='splide__arrow--next'] {
    // because we're faking the right side with padding
    // we need to add this inset-inline-end to the "next"
    // arrow so that it lines up with other arrows
    // from other carousels
    inset-inline-end: 1em;
  }
}

a.show_poster {
  height: auto;
  aspect-ratio: 2 / 3;

  @include breakpoint($max: $sm) {
    display: none;
  }
}
