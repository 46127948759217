@use '../includes' as *;

.passport_thumbnail {
  --corner-radius: 8px;

  container-type: inline-size;
  position: relative;
  border-radius: var(--corner-radius) var(--corner-radius) 0 0;

  @include breakpoint($sm) {
    --corner-radius: 10px;

    @media (hover: hover) {
      &:hover {
        a.passport_thumbnail_image_link {
          outline-color: $white;
        }
      }
    }
  }

  @include breakpoint($md) {
    --corner-radius: 15px;
  }
}

.passport_thumbnail_passport_badge {
  position: absolute;
  inset: 0 auto auto 0;
  z-index: 1;
  width: 22cqw;
  min-width: 32px;

  @container (min-width: 340px) {
    width: 13cqw;
  }
}

a.passport_thumbnail_image_link {
  display: block;
  position: relative;
  aspect-ratio: 16 / 9;
  overflow: hidden;
  // these transitions are governed by parent element hover states
  transition-property: outline-color, opacity;
  transition-duration: var(--duration);
  border-radius: var(--corner-radius);
  outline: var(--border-thickness) solid transparent;

  @container (min-width: 340px) {
    &::after {
      content: '';
      display: block;
      position: absolute;
      inset: auto 0 0;
      z-index: 1;
      aspect-ratio: 19 / 9;
      background: linear-gradient(
        to top,
        rgba($midnight, 0.85) 30%,
        transparent 100%
      );
    }
  }
}

.passport_thumbnail_image {
  width: 100%;
}

.passport_show_info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: $g8;
  margin-block-start: $g12;
  pointer-events: none;

  @container (min-width: 340px) {
    position: absolute;
    inset: auto 0 0;
    z-index: 2;
    padding: $g16;
  }
}

.show_logo {
  width: 80px;

  @container (min-width: 340px) {
    width: 31cqw;
  }
}

.passport_buttons {
  display: none;
  pointer-events: auto;

  @container (min-width: 340px) {
    display: inline-flex;
    gap: $g12;
  }
}

// text fallback in case the show doesn't have a 4x1 white logo
.show_title {
  @include h3;
}

.show_description {
  @include body-copy-3($pebble);
  @include typography-truncate(3);

  @container (min-width: 340px) {
    max-width: 45ch;
    color: $fog;
  }

  @container (min-width: 475px) {
    @include body-copy-1;
  }
}
