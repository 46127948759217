@use '../includes' as *;

$bp: $sm;

.donate_highlight {
  position: relative;
  margin-block-end: var(--spacing-block);
  padding: $g20 $g40 0 $g20;
  background-color: $medium-blue;

  @include breakpoint($bp) {
    padding: $g40 $g96 $g16 calc(100% - 40% - $g96);
  }

  @include breakpoint($md) {
    padding: $g64 100px $g28 calc(100% - 40% - 100px);
  }

  @include breakpoint($xl) {
    padding: 100px 100px $g40 calc(100% - 40% - 150px);
  }
}

.donate_highlight_station_info {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: $g12;
  margin-block-end: $g32;

  @include breakpoint($bp) {
    margin-block-end: 0;
  }
}

.donate_highlight_logo {
  display: flex;
  justify-content: center;
  width: 44%;
  height: auto;

  img {
    width: 100%;
    height: auto;
    max-height: 45px;
  }
}

.donate_highlight_text {
  @include body-copy-1($white);

  max-width: 260px;
  text-align: center;
}

.donate_highlight_illustration {
  width: 59%;
  height: auto;
  margin: 0 auto;
  transform: scaleX(-1);
  // small easter egg - you get to see her flip when your resize your browser 🐣
  transition: transform var(--duration-half) ease-in;

  @include breakpoint($bp) {
    position: absolute;
    inset: auto auto 0 3%;
    width: 40%;
    max-width: 310px;
    max-height: 230px;
    transform: scaleX(1);
  }

  @include breakpoint($md) {
    max-width: 432px;
    max-height: 320px;
  }

  @include breakpoint($xl) {
    inset-inline-start: 4%;
    max-width: 513px;
    max-height: 380px;
  }

  @include breakpoint($max-width) {
    inset-inline-start: 17%;
  }
}

.dontate_highlight_social_links {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: $g12;

  ul {
    margin: 0 auto;
  }

  @include breakpoint($bp) {
    flex-direction: row;
    align-items: center;
    margin-block-start: $g16;
  }

  @include breakpoint($md) {
    margin-block-start: $g56;
  }

  @include breakpoint($xl) {
    margin-block-start: $g72;
  }
}

.donate_highlight_social_links_intro {
  @include h5($white);
}
