@use '../../../components/includes' as *;

body:has(.shows_landing__app) main {
  overflow-x: visible;
}

.shows_landing__app {
  display: block;
  max-width: $max-width;
  margin: $g24 auto;
  background-color: var(--lightest-darkest-background-color);

  @include breakpoint($xxxxs) {
    margin-inline: calc(var(--spacing-inline) / 2);
  }

  @include breakpoint($sm) {
    margin-block-start: 0;
  }

  @include breakpoint($smmd) {
    display: flex;
    position: relative;
    margin-inline: var(--spacing-inline);
    padding: $g72 0 0 0;
  }
}

.outside_access {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: $max-width;
  margin: $g48 auto;

  h1 {
    @include h1;
  }
}
