@use '../includes' as *;

.passport_thumbnail_carousel {
  padding-inline-end: var(--spacing-inline);

  [class*='splide__track'] {
    // allows for expanded thumbnail to be visible on hover
    overflow: visible;
  }

  @media (hover: hover) {
    &:has(div[class*='passport_thumbnail']:hover) {
      div[class*='passport_thumbnail']:not(:hover) {
        opacity: 0.5;
        background-color: $midnight;
      }
    }
  }
}

button.splide__arrow {
  @include splide-arrow;

  & {
    inset-block-start: 21cqw;
    margin-block-start: -30px;
  }

  @include breakpoint($xs) {
    inset-block-start: 18cqw;
  }

  @include breakpoint($sm) {
    inset-block-start: 62%;
  }
}

// bumping this arrow to the left a little because it overlaps
// show logos
.splide__arrow[class*='splide__arrow--prev'] {
  @include breakpoint($sm) {
    inset-inline-start: -2cqw;
  }
}
