@use '../includes' as *;

.back_button {
  display: inline-block;
  width: clamp(20px, 3vw + 0.1em, 25px);
  height: clamp(20px, 3vw + 0.1em, 25px);
  padding: 0;
  border: 0;
  background-color: transparent;
  cursor: pointer;

  svg {
    fill: $white;
    filter: drop-shadow(0 1px 15px rgba($midnight, 0.6));
  }
}
