@use '../../includes' as *;

.station_tile__button {
  all: unset;

  @include h6;

  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 260px;
  height: 65px;
  padding-inline: $g12;
  transition: border-color 0.3s ease-in;
  border: 3px solid transparent;
  border-radius: 2px;
  background-color: rgba($white, 0.15);
  font-size: $f12;
  text-align: center;
  cursor: pointer;

  @include breakpoint($sm) {
    width: 350px;
  }

  &:focus-visible {
    outline: 3px solid $light-blue;
  }
}

.station_tile__button[aria-pressed='true'] {
  border: 3px solid $teal;
}

.station_tile__logo {
  display: flex;
  align-items: center;
  height: 100%;
  padding-block: $g8;

  img {
    width: auto;
    max-height: 100%;
  }
}

.station_tile__info {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-inline-start: auto;
  text-align: end;
}

.station_tile__location {
  @include body-copy-3($pebble);
}
