@use '../includes' as *;

@mixin section() {
  display: flex;
  flex-direction: column;
  gap: $g12;
}

@mixin content-metadata-btn {
  @include circle-icon-button;

  --color: #{$pebble};

  transition-property: border-color;
  border-color: var(--color);

  svg,
  path {
    // Known issue in Safari that svgs need
    // to set a hacky "width: 100%" to work
    // https://stackoverflow.com/a/37594943
    width: 100%;
    max-width: 80%;
    max-height: 80%;
    transition-property: fill, stroke;
    transition-duration: var(--duration-half);
    fill: var(--color);
  }

  &:hover {
    --color: #{$fog};
  }

  &:focus {
    outline: $focusstatelight;
  }

  // Targets only mobile Safari SVGs because they
  // seem to scale differently than other browsers.
  // This prevents the SVGs from becoming way too
  // tiny at mobile widths.
  @supports (-webkit-touch-callout: none) {
    svg,
    path {
      max-width: 100%;
      max-height: 100%;
    }
  }
}

.content_metadata {
  display: flex;
  flex-direction: column;
  gap: $g20;
  max-width: 400px;

  @include breakpoint($sm) {
    gap: $g32;
  }
}

.content_metadata__sub_title {
  @include h6($pebble);

  text-transform: uppercase;
}

.content_metadata__title {
  @include h6($pebble);

  text-transform: uppercase;
}

.content_metadata__genre {
  @include section;
}

.content_metadata__genre_badges {
  display: flex;
  flex-wrap: wrap;
  gap: $g8;
  row-gap: $g16;
}

.content_metadata__rating {
  @include section;
}

.content_metadata__follow_us {
  @include section;
}

.content_metadata__social_stuff {
  display: flex;
  align-items: center;
  gap: $g8;
}

.content_metadata__share_this_video {
  @include section;
}

.content_metadata__share_this_video__links {
  display: flex;
  gap: $g8;
}

.content_metadata__share_this_video__links__embed_button {
  @include content-metadata-btn;
}
