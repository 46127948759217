@use '../includes' as *;

.from_franchise {
  @include h6;

  display: flex;
  align-items: center;
}

.from_franchise__link {
  padding-inline-start: $g12;
}
