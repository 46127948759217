@use '../includes' as *;

.passport_row_header {
  display: grid;
  grid-template-areas:
    'title'
    'description'
    'ctas';
  grid-template-columns: 1fr;
  gap: $g8;
  margin-block-end: $g16;
  padding-inline-end: var(--spacing-inline);

  @include breakpoint($md) {
    grid-template-areas:
      'title       ctas'
      'description ctas';
    grid-template-columns: minmax(min-content, 560px) 430px;
    justify-content: space-between;
  }
}

.passport_row_title {
  @include h2;

  grid-area: title;
}

.passport_row_description {
  @include body-copy-2($pebble);

  grid-area: description;
  max-width: 560px;
}

a.passport_row_learn_more_link {
  @include body-copy-3;

  white-space: nowrap;

  &:link,
  &:visited {
    color: $yellow;
    font-weight: $bold;
  }

  @include breakpoint($sm) {
    display: none;
  }
}

a.passport_row_learn_more_link_button {
  display: none;

  @include breakpoint($sm) {
    display: inline-flex;
  }
}

a.passport_row_explore_button,
a.passport_row_donate_button {
  max-width: fit-content;
}

.passport_row_ctas {
  display: inline-flex;
  grid-area: ctas;
  flex-direction: column;
  gap: $g12;

  @include breakpoint($sm) {
    flex-direction: row;
  }

  @include breakpoint($md) {
    place-self: end end;
  }
}
