@use '../../components/includes' as *;

.urgent_message_badge_wrapper {
  display: flow-root;
  position: sticky;
  z-index: 21;
  max-height: 0;
  overflow: clip;
  animation: banner-slide-down 1.3s ease-in forwards;
  animation-delay: 0.2s;

  @media (prefers-reduced-motion: reduce) {
    animation-duration: 0;
    animation-delay: 0;
  }

  // if the badge has a height set, then we need the container query
  &[style*='height'] {
    container-type: size;
  }
}

.urgent_message_badge {
  display: flex;
  inset-inline: 0;
  inset-block-start: 0;
  z-index: 21;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: calc(var(--gutter) * 2);
  height: calc(100% - #{$g64});
  margin-block: $g32;
  padding-inline: var(--spacing-inline) calc(var(--spacing-inline) * 2.5);
  overflow: clip;
  animation: banner-fade-in 0.5s ease-in forwards;
  animation-delay: 0.9s;
  opacity: 0;

  @media (prefers-reduced-motion: reduce) {
    animation-duration: 0;
    animation-delay: 0;
  }

  @include breakpoint($md) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: calc(var(--gutter) * 3);
    padding-inline: var(--spacing-inline);

    @container (min-height: 800px) {
      flex-direction: column;
      justify-content: center;
      text-align: center;
    }
  }
}

.teal {
  background-color: $teal;
  color: $white;
}

.yellow {
  background-color: $yellow;
  color: $white;
}

.red {
  background-color: $red;
  color: $white;
}

.urgent_message_badge__close_button {
  all: unset;
  position: absolute;
  inset-block-start: 10px;
  inset-inline-end: var(--spacing-inline);
  width: 20px;
  cursor: pointer;

  svg,
  path {
    fill: $white;
  }

  @include breakpoint($sm) {
    inset-block-start: 16px;
    inset-inline-end: 20px;
  }
}

.urgent_message_badge__headline {
  color: inherit;
  font-size: $f20;
  font-weight: $bold;
  line-height: 1.25;
  text-wrap: pretty;

  @include breakpoint($sm) {
    @include h1($white);

    max-width: 400px;
    color: inherit;
  }
}

.urgent_message_badge__description {
  @include h4($white);

  max-width: 450px;
  color: inherit;
  text-wrap: pretty;

  @include breakpoint($md) {
    max-width: 650px;
  }
}

a.urgent_message_badge__button {
  white-space: nowrap;

  &:hover,
  &:focus {
    background-color: rgba($white, 0.6);
  }
}

@keyframes banner-slide-down {
  0% {
    max-height: 0;
  }

  100% {
    max-height: 2000px;
  }
}

@keyframes banner-fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
