@use '../../../components/includes' as *;

.station_finder_page {
  display: grid;
  grid-template-areas:
    'title'
    'name'
    'info'
    'contact'
    'button'
    'date';
  gap: calc(var(--spacing-block) / 2);
  margin-block: $g40;
  margin-inline: var(--spacing-inline);

  @include breakpoint($md) {
    grid-template-areas:
      'title title'
      'name name'
      'info contact'
      'button button'
      'date date';
    grid-template-columns: 1fr 1fr;
  }
}

.station_finder_page_title {
  @include h1;

  grid-area: title;
  padding-block-end: calc(var(--spacing-block) / 2);
  border-block-end: 1px solid $slate;
}

.station_finder_station_info {
  grid-area: info;

  h3 {
    @include h3;

    margin-block-end: $g16;
  }

  p {
    @include body-copy-2;

    margin-block-end: $g8;

    a {
      text-decoration: underline;
    }
  }
}

.station_finder_station_logo_name {
  @include h2;

  display: flex;
  grid-area: name;
  align-items: center;
  gap: $g32;
}

.station_finder_change_station_button {
  grid-area: button;
  align-self: center;
  margin-inline: auto;
}

.station_finder_social_links {
  display: flex;
  align-items: center;
  gap: $g12;
}

.station_finder_contact {
  grid-area: contact;
  padding: $g24;
  border: 1px solid $slate;

  h3 {
    @include h3;

    margin-block-end: $g16;
  }

  address {
    font-style: normal;

    p {
      @include body-copy-2;

      margin-block-end: $g8;
    }
  }
}

.station_finder_updated_date {
  @include body-copy-2;

  grid-area: date;
  text-align: center;
}
